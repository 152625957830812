import React, { useState } from "react";
import logo from "../assets/FooterLogo.png";
import Modal from "./Modal";

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <header className="flex items-center justify-between px-6 lg:px-20 py-4 bg-white">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-16 -ml-2" />
      </div>

      <button
        className="px-6 py-3 text-white bg-primary font-medium rounded-lg hover:bg-green-500"
        onClick={openModal}
      >
        Join Waitlist
      </button>
      {isModalOpen && <Modal onClose={closeModal} />}
    </header>
  );
};

export default Header;

// src/App.tsx
import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import MissionSection from "./components/MissionSection";
import JourneySection from "./components/JourneySection";
import Footer from "./components/Footer";
import Marketplace from "./components/Marketplace";
import MeetOurGardeners from "./components/MeetOurGardeners";

const App: React.FC = () => {
  return (
    <div className="font-jost">
      <Header />
      <HeroSection />
      <MissionSection />
      <JourneySection />
      {/* Additional sections here: GardenerSection, ProductSection, Footer */}
      <MeetOurGardeners />
      <Marketplace />
      <Footer />
    </div>
  );
};

export default App;
